/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/Poppins-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'PoppinsBold';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/Poppins-Bold.ttf') format('ttf');
}

* {
  font-family: 'Poppins', sans-serif;
}

@layer base {
  :root {
  --ion-background-color: #320B54D4;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
