ion-menu ion-content {
  --background: linear-gradient(
    90deg,
    var(--ion-color-primary-shade) 0%,
    var(--ion-color-primary) 70%
  ) !important;
}

ion-menu {
  ion-content {
    --ion-background-color: linear-gradient(
      90deg,
      var(--ion-color-primary-shade) 0%,
      var(--ion-color-primary) 70%
    ) !important;
    --ion-text-color: white;
  }
}

ion-item,
ion-menu-toggle,
a {
  background: transparent !important;
}
